import ArticleCard, { ArticleCardProps } from "@/components/ArticleCard";
import { RelatedArticlesContainer } from "./styles";

type RelatedArticlesProps = {
  articles?: ArticleCardProps[];
};

const RelatedArticles: React.FC<RelatedArticlesProps> = (props) => {
  const { articles } = props;
  return (
    <RelatedArticlesContainer>
      {articles &&
        articles.map((article, index) => (
          <ArticleCard key={`article-${index}`} {...article} />
        ))}
    </RelatedArticlesContainer>
  );
};

export default RelatedArticles;
