import { Container, Time, Excerpt, HeadingWrapper, CategoryTimeWrapper, Category, Heading, TextContainer } from "./styles";
import Thumbnail, { ThumbnailProps } from "./Thumbnail";
import { isValid, format } from "date-fns";
import { useMemo } from "react";
import { useRouter } from "next/router";
import { getDateFnsLocale } from "@/lib/helpers";
import Link from "next/link";

export type ArticleCardProps = {
  className?: string;
  slug: string;
  releaseDate: string;
  category: string | null;
  heading: string;
  excerpt: string;
  thumbnail: ThumbnailProps;
  headingStyle?: string;
};

const ArticleCard: React.FC<ArticleCardProps> = (props) => {
  const { locale = "it" } = useRouter();
  const { releaseDate, category, heading, excerpt, thumbnail, slug, className, headingStyle = "h3" } = props;

  const date = useMemo(() => {
    const d = new Date(releaseDate);
    if (isValid(d)) {
      return format(d, "PPP", { locale: getDateFnsLocale(locale) });
    } else {
      console.error("Invalid date:", releaseDate);
      return null;
    }
  }, [releaseDate, locale]);

  return (
    <Container className={className}>
      <Thumbnail asset={thumbnail.asset} />
      <TextContainer>
        <CategoryTimeWrapper>
          {category && <Category>{category}</Category>}
          {date && <Time>{date}</Time>}
        </CategoryTimeWrapper>
        <Link href={"/news/" + slug} passHref>
          <HeadingWrapper as="a">
            {headingStyle && headingStyle === "h2" && <Heading as="h2">{heading}</Heading>}
            {headingStyle && headingStyle === "h3" && <Heading as="h3">{heading}</Heading>}
          </HeadingWrapper>
        </Link>
        <Excerpt>{excerpt}</Excerpt>
      </TextContainer>
    </Container>
  );
};

export default ArticleCard;
