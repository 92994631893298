import styled from "styled-components";
import { ImageWrapper as _ImageWrapper } from "@/lib/styles";

export const Container = styled.div`
  flex: 1 0 40%;
  position: relative;
`;

export const Wrapper = styled(_ImageWrapper)`
  width: 100%;
  img {
    transition: transform 0.3s ease-out, opaciy 0.3s ease-in !important;
  }
  padding-bottom: calc(100% * 267 / 343);

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding-bottom: 50%;
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-bottom: calc(100% * 306 / 393);
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    padding-bottom: calc(100% * 355 / 566);
  }
`;
