import { Container, Wrapper } from "./styles";
import Image from "next/image";
import { imageLoader } from "@/lib/sanity/client";
import { useState } from "react";

export type ThumbnailProps = {
  asset: {
    url: string;
  };
};

const Thumbnail: React.FC<ThumbnailProps> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const { asset } = props;
  return (
    <Container>
      <Wrapper $hasLoaded={loaded}>
        <Image
          src={asset.url}
          layout="fill"
          objectFit="cover"
          loader={imageLoader}
          onLoadingComplete={() => setLoaded(true)}
          alt="" // TODO: provide alt
        />
      </Wrapper>
    </Container>
  );
};

export default Thumbnail;
